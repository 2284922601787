import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TableV2 } from "ui";
import {
  MiterAPI,
  AssignedTeamMemberOnboardingTask,
  TeamMemberOnboardingTask,
  AggregatedI9,
} from "dashboard/miter";
import { useRefetchActionableItems } from "dashboard/hooks/atom-hooks";
import { Notifier } from "dashboard/utils";
import { Check } from "phosphor-react";
import { ColumnConfig } from "ui/table-v2/Table";
import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { getDueDate } from "miter-components/onboarding-v2/utils";
import { taskTypeLabels } from "miter-components/onboarding-v2/OnboardingChecklistV2";
import { DateTime } from "luxon";
import { OnboardingChecklistV2AdminWizard } from "miter-components/onboarding-v2/OnboardingChecklistV2AdminWizard";

type Props = {
  fetchOnboardingTasks: () => Promise<AssignedTeamMemberOnboardingTask[]>;
};

export type OnboardingTaskRow = AssignedTeamMemberOnboardingTask & {
  i9?: AggregatedI9 | null;
};

export const OnboardingTasksTable: React.FC<Props> = ({ fetchOnboardingTasks }) => {
  const refetchActionableItems = useRefetchActionableItems();
  const [tableData, setTableData] = useState<OnboardingTaskRow[]>([]);
  const [selectedRows, setSelectedRows] = useState<OnboardingTaskRow[]>([]);
  const [taskUpdateLoading, setTaskUpdateLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [showOnboardingWizard, setShowOnboardingWizard] = useState(false);
  const [onboardingWizardItems, setOnboardingWizardItems] = useState<OnboardingTaskRow[]>([]);

  const columns: ColumnConfig<OnboardingTaskRow>[] = useMemo(
    () => [
      {
        field: "type",
        headerName: "Task",
        dataType: "string",
        valueFormatter: (params: ValueFormatterParams<OnboardingTaskRow>) => {
          return taskTypeLabels[params.value];
        },
      },
      { field: "team_member.friendly_id", headerName: "Team member ID", dataType: "string" },
      {
        field: "team_member.full_name",
        headerName: "Team member",
        dataType: "string",
      },
      {
        field: "admin_status",
        headerName: "Status",
        dataType: "string",
        displayType: "badge",
        colors: {
          actionable: "light-green",
          blocked_by_employee_i9: "lightgray",
          missing_start_date: "light-red",
        },
      },
      {
        field: "due_days_from_start",
        headerName: "Due date",
        dataType: "string",
        valueGetter: (params: ValueGetterParams<OnboardingTaskRow>) => {
          if (!params.data?.due_days_from_start) return "No due date set";
          const startDate = params.data?.team_member?.start_date;
          return startDate && params.data?.due_days_from_start
            ? getDueDate(startDate, params.data?.due_days_from_start)
            : null;
        },
        valueFormatter: (params: ValueFormatterParams<OnboardingTaskRow>) => {
          if (!params.value) return "-";
          if (params.value === "No due date set") return params.value;
          return DateTime.fromISO(params.value).toFormat("LLL dd, yyyy");
        },
        sortable: true,
      },
    ],
    []
  );

  const refetchOnboardingTasksData = useCallback(async () => {
    const tasks = await fetchOnboardingTasks();
    setTableData(tasks);
    setDataLoading(false);
  }, [fetchOnboardingTasks]);

  useEffect(() => {
    refetchOnboardingTasksData();
  }, [refetchOnboardingTasksData]);

  const createTaskUpdater = useCallback(
    (checklistId: string) =>
      async (updatedTask: Partial<OnboardingTaskRow>): Promise<void> => {
        try {
          const response = await MiterAPI.team_member_onboarding_checklists.update_tasks(checklistId, {
            updatedTasks: [updatedTask as TeamMemberOnboardingTask],
          });
          if (response.error) throw new Error(response.error);
          await refetchOnboardingTasksData();
        } catch (error) {
          console.error("Error updating task", error);
          Notifier.error("Error updating task.");
        }
      },
    [refetchOnboardingTasksData]
  );

  // TODO: Remove this when fully shipped, using it for testing for now
  // eslint-disable-next-line unused-imports/no-unused-vars
  const forceCompleteTasks = useCallback(async () => {
    try {
      setTaskUpdateLoading(true);
      // Group the onboarding tasks by checklist_id
      const checklistIdToTasks: Record<string, AssignedTeamMemberOnboardingTask[]> = selectedRows.reduce(
        (acc, task) => {
          acc[task.team_member_onboarding_checklist_id] = acc[task.team_member_onboarding_checklist_id] || [];
          acc[task.team_member_onboarding_checklist_id].push(task);
          return acc;
        },
        {}
      );
      // Update each checklist with the selected tasks
      await Promise.all(
        Object.entries(checklistIdToTasks).map(([checklistId, tasks]) =>
          MiterAPI.team_member_onboarding_checklists.update_tasks(checklistId, {
            updatedTasks: tasks.map((task) => ({
              _id: task._id,
              status: "complete",
            })),
          })
        )
      );

      Notifier.success("Tasks completed successfully");
      setSelectedRows([]);
      setTaskUpdateLoading(false);
      await refetchOnboardingTasksData();
      // Refetch the actionable items counts
      await refetchActionableItems();
    } catch (error) {
      Notifier.error("Failed to complete tasks");
    }
  }, [selectedRows, refetchOnboardingTasksData, refetchActionableItems]);

  const getI9ForTask = useCallback(async (task: OnboardingTaskRow): Promise<AggregatedI9 | undefined> => {
    if (task.type !== "i9_employer") return undefined;

    try {
      const res = await MiterAPI.i_9s.search([{ field: "team_member_id", value: task.team_member._id }]);
      if (res.error) throw new Error(res.error);

      if (res.length > 0 && res[0]) {
        return res[0];
      }
    } catch (error) {
      Notifier.error("Failed to get I-9");
    }
    return undefined;
  }, []);

  const completeTasks = useCallback(async () => {
    setTaskUpdateLoading(true);
    const tasksWithI9s = await Promise.all(
      selectedRows.map(async (task) => {
        const i9 = await getI9ForTask(task);
        return { ...task, i9 };
      })
    );
    setOnboardingWizardItems(tasksWithI9s);
    setShowOnboardingWizard(true);
    setTaskUpdateLoading(false);
  }, [selectedRows, getI9ForTask]);

  const onRowClick = useCallback(
    async (row: OnboardingTaskRow) => {
      const i9 = await getI9ForTask(row);
      setOnboardingWizardItems([{ ...row, i9 }]);
      setShowOnboardingWizard(true);
    },
    [getI9ForTask]
  );

  const dynamicActions = useMemo(() => {
    return [
      {
        label: "Complete",
        className: `button-2 table-button`,
        action: completeTasks,
        icon: <Check weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => selectedRows.length > 0,
        loading: taskUpdateLoading,
      },
    ];
  }, [selectedRows, taskUpdateLoading, completeTasks]);

  const renderWizard = () => {
    return (
      <OnboardingChecklistV2AdminWizard
        onComplete={() => {
          Notifier.success("Completed selected tasks.");
          setShowOnboardingWizard(false);
          setOnboardingWizardItems([]);
        }}
        onExit={() => {
          setShowOnboardingWizard(false);
          setOnboardingWizardItems([]);
        }}
        createTaskUpdater={createTaskUpdater}
        onboardingTasks={onboardingWizardItems}
        getI9ForTask={getI9ForTask}
      />
    );
  };

  return (
    <>
      {showOnboardingWizard && renderWizard()}
      <TableV2
        id="onboarding-tasks-table"
        title="Admin onboarding tasks"
        resource="onboarding tasks"
        columns={columns}
        data={tableData}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        wrapperClassName="base-ssr-table"
        dynamicActions={dynamicActions}
        containerClassName="onboarding-tasks-table-container"
        onClick={onRowClick}
        isLoading={dataLoading}
        showReportViews={true}
      />
    </>
  );
};
