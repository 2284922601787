import { AggregatedTeamMember } from "dashboard/miter";
import React, { FC, useState } from "react";
import { Button } from "ui";
import styles from "./TeamMemberOnboardingV2.module.css";

type Props = {
  teamMember: AggregatedTeamMember;
};

import TeamMemberWizard, { TeamMemberWizardMode } from "dashboard/components/team-members/TeamMemberWizard";

export const TeamMemberOnboardingV2: FC<Props> = ({ teamMember }) => {
  const hasOnboardingChecklist = !!teamMember.onboarding_checklist;

  const [showChecklistBuilderWizard, setShowChecklistBuilderWizard] = useState(false);
  const [checklistMode, setChecklistMode] = useState<TeamMemberWizardMode>("onboarding_checklists");

  return (
    <div>
      <h2>Onboarding</h2>
      <div>
        {hasOnboardingChecklist
          ? `Edit ${teamMember.first_name}'s onboarding checklists and manage their tasks.`
          : `Create a checklist for ${teamMember.first_name} to complete their onboarding.`}
      </div>
      <div className="vertical-spacer-small"></div>
      <div>
        {/* TODO: Eventually these will be action buttons above the new hire and admin onboarding tasks tables */}
        {hasOnboardingChecklist && (
          <div className={styles.buttonContainer}>
            <Button
              className="no-margin button-2"
              text="Edit new hire checklist"
              onClick={() => {
                setShowChecklistBuilderWizard(true);
                setChecklistMode("new_hire_onboarding_checklist");
              }}
            />
            <Button
              className="no-margin button-2"
              text="Edit admin checklist"
              onClick={() => {
                setShowChecklistBuilderWizard(true);
                setChecklistMode("admin_onboarding_checklist");
              }}
            />
          </div>
        )}
        {!hasOnboardingChecklist && (
          <Button
            className="no-margin button-2"
            text={"Create team member checklists"}
            onClick={() => {
              setShowChecklistBuilderWizard(true);
              setChecklistMode("onboarding_checklists");
            }}
          />
        )}
      </div>
      {showChecklistBuilderWizard && (
        <TeamMemberWizard
          teamMember={teamMember}
          mode={checklistMode}
          onExit={() => setShowChecklistBuilderWizard(false)}
          onComplete={() => setShowChecklistBuilderWizard(false)}
        />
      )}
    </div>
  );
};
