import { TFunction } from "i18next";
import { DateTime } from "luxon";
import pluralize from "pluralize";

export const getPaymentMethodOptions = (t: TFunction): Array<{ label: string; value: string }> => [
  { label: t("Direct deposit"), value: "direct_deposit" },
  { label: t("Paper check"), value: "manual" },
];

export const getDueDateString = (startDate: string, dueDaysFromStart: number): string => {
  const startDt = DateTime.fromISO(startDate);
  const dueDateObj = startDt.plus({ days: dueDaysFromStart });
  const daysUntilDue = Math.ceil(dueDateObj.diffNow("days").days);

  if (daysUntilDue < 0) {
    return `Due ${pluralize("day", Math.abs(daysUntilDue), true)} ago`;
  } else {
    return `Due in ${pluralize("day", daysUntilDue, true)}`;
  }
};

export const getDueDate = (
  startDate: string | null | undefined,
  dueDaysFromStart: number | null | undefined
): string | null => {
  if (!startDate || !dueDaysFromStart) {
    return null;
  }
  const startDt = DateTime.fromISO(startDate);
  const dueDateObj = startDt.plus({ days: dueDaysFromStart });
  return dueDateObj.toISODate();
};
